<template>
<main class="page" :class="page.uid" :key="page.uid">
  <section v-for="(section, index) in page.content" ref="section" :key="index" :id="'section-'+index">
    <div class="viewer" @click="scrollToNext(index)">
      <div class="left" :style="{ backgroundColor: section.left_color, color: section.left_textcolor }">
        <template v-if="section.left == 'title'">
          <div class="inner-text inner-title">
            <h1 class="title">{{ section.left_title }}</h1>
          </div>
        </template>
        <template v-if="section.left == 'image'">
          <img :src="section.left_image.url" :class="{ mirror: section.left_mirror === 'true' }" alt="">
        </template>
        <template v-if="section.left == 'video'">
          <video :src="section.left_video.url" playsinline autoplay muted loop></video>
        </template>
        <template v-if="section.left == 'text'">
          <div class="inner-text" v-html="section.left_text"></div>
        </template>
        <template v-if="section.left == 'news'">
          <div class="inner-news" v-html="section.left_news"></div>
        </template>
      </div>
      <div class="right" :style="{ backgroundColor: section.right_color, color: section.right_textcolor }">
        <template v-if="section.right == 'title'">
          <div class="inner-text inner-title">
            <h1 class="title">{{ section.right_title }}</h1>
          </div>
        </template>
        <template v-if="section.right == 'image'">
          <img :src="section.right_image.url" :class="{ mirror: section.right_mirror === 'true' }" alt="">
        </template>
        <template v-if="section.right == 'video'">
          <video :src="section.right_video.url" playsinline autoplay muted loop></video>
        </template>
        <template v-if="section.right == 'text'">
          <div class="inner-text" v-html="section.right_text"></div>
        </template>
        <template v-if="section.right == 'news'">
          <div class="inner-news" v-html="section.right_news"></div>
        </template>
      </div>
    </div>
  </section>
  <section id="end"></section>
  <section></section>
  <section></section>
</main>
</template>

<script>
export default {
  computed: {
    page() {
      return this.$store.getters.getPageByUID(this.$route.name)
    },
  },
  mounted() {
    window.addEventListener('scroll', this.onScroll)
    window.addEventListener('resize', this.onScroll)
    this.$root.$on('scrollToTop', this.scrollToTop)
  },
  destroyed() {
    window.removeEventListener('scroll', this.onScroll)
    window.removeEventListener('resize', this.onScroll)
    this.$root.$off('scrollToTop', this.scrollToTop)
  },
  methods: {
    scrollToTop() {
      this.$scrollTo('#section-0', 1000)
    },
    scrollToNext(index) {
      if (index == this.$refs.section.length - 1) {
        this.$scrollTo('#end', 1000)
      } else {
        let id = '#' + this.$refs.section[index + 1].id
        this.$scrollTo(id, 1000)
      }
    },
    onScroll() {
      let sections = this.$refs.section
      for (var i = 0; i < sections.length; i++) {
        var section = sections[i]
        var sectionStart = section.offsetTop - section.clientHeight
        var sectionEnd = sectionStart + section.clientHeight
        let left = section.querySelector('.left')
        let right = section.querySelector('.right')
        if (window.scrollY <= sectionStart) {
          // console.log(i + ': coming in')
          if (window.innerWidth > 768) {
            left.style.width = '0%'
            right.style.width = '0%'
          } else {
            left.style.height = '0%'
            right.style.height = '0%'
          }
          section.classList = ""
          section.classList.add('future')
        } else if (window.scrollY >= sectionStart && window.scrollY <= sectionEnd) {
          // console.log(i + ': in view')
          let percent = ((window.scrollY - sectionStart) / (sectionEnd - sectionStart)) * 50
          if (window.innerWidth > 768) {
            left.style.width = percent + '%'
            right.style.width = percent + '%'
            left.style.height = '100%'
            right.style.height = '100%'
            left.style.right = '50%'
            right.style.left = '50%'
            left.style.bottom = '0'
            right.style.top = '0'
          } else {
            left.style.height = percent + '%'
            right.style.height = percent + '%'
            left.style.width = '100%'
            right.style.width = '100%'
            left.style.bottom = '50%'
            right.style.top = '50%'
            left.style.right = '0'
            right.style.left = '0'
          }
          section.classList = ""
          section.classList.add('active')
        } else if (window.scrollY > sectionEnd && window.scrollY < (sectionEnd + section.clientHeight)) {
          // console.log(i + ': past')
          let percent = ((window.scrollY - sectionStart) / (sectionEnd - sectionStart)) * 50
          if (window.innerWidth > 768) {
            left.style.width = '50%'
            right.style.width = '50%'
            left.style.height = '100%'
            right.style.height = '100%'
            left.style.bottom = '0'
            right.style.top = '0'
            if ((i + 1) === sections.length) {
              left.style.right = '50%'
              right.style.left = '50%'
            } else {
              left.style.right = percent + '%'
              right.style.left = percent + '%'
            }
          } else {
            left.style.height = '50%'
            right.style.height = '50%'
            left.style.width = '100%'
            right.style.width = '100%'
            left.style.right = '0'
            right.style.left = '0'
            if ((i + 1) === sections.length) {
              left.style.bottom = '50%'
              right.style.top = '50%'
            } else {
              left.style.bottom = percent + '%'
              right.style.top = percent + '%'
            }
          }
          section.classList = ""
          section.classList.add("past")
        } else if (window.scrollY > (sectionEnd + section.clientHeight)) {
          // console.log(i + ': last')
          if (window.innerWidth > 768) {
            left.style.right = '50%'
            right.style.left = '50%'
            left.style.bottom = '0'
            right.style.top = '0'
          } else {
            left.style.bottom = '50%'
            right.style.top = '50%'
            left.style.right = '0'
            right.style.left = '0'
          }
        }
      }
      if (window.scrollY > (sections[0].clientHeight * (sections.length - 0.5))) {
        this.$root.$emit('openMenu')
      } else {
        this.$root.$emit('closeMenu')
      }
    },
  }
}
</script>

<style lang="scss">
h1 {
    &.title {
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        transform: translateY(-50%);
        text-align: center;
        font-size: calc(4.5 * var(--fs));
        line-height: 1.1;
        text-transform: uppercase;
        padding: var(--lh);
        @media only screen and (max-width: 768px) {
            font-size: calc(4 * var(--fs));
        }
    }
}
.orange {
    background-color: #FFA400;
}
.white {
    background-color: #FFF;
}
.home {
    z-index: 2;
}
section {
    width: 100vw;
    height: 100vh;
    pointer-events: none;
    overflow-x: hidden;
    @media only screen and (max-width:768px) {
        height: 100vh;
    }
    &:last-of-type,
    &:nth-last-of-type(2) {
        height: 0;
    }
    &.past {
        .inner-text {
            // overflow: scroll;
        }
    }
    .viewer {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        z-index: 0;
        cursor: pointer;
        @media only screen and (max-width:768px) {
            height: 100%;
        }
        .left,
        .right {
            position: absolute;
            right: 50%;
            width: 0;
            top: 0;
            bottom: auto;
            height: 100%;
            overflow: hidden;
            pointer-events: auto;
            transform: translate3d(0,0,0);
            @media only screen and (max-width:768px) {
                bottom: 50%;
                top: auto;
                right: 0;
                width: 100%;
                height: 0;
            }
            .inner-news {
                display: flex;
                flex-direction: column;
                justify-content: center;
                height: 100%;
                padding: calc(1 * var(--lh)) var(--lh);
                width: 50vw;
                text-align: center;
                cursor: auto;
                @media only screen and (max-width:768px) {
                    padding: calc(3 * var(--lh)) var(--lh);
                    width: 100%;
                }
                p {
                    margin-bottom: var(--lh);
                    &:last-of-type {
                        margin-bottom: 0;
                    }
                }
            }
            .inner-text {
                cursor: auto;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                height: 100%;
                padding: calc(1 * var(--lh)) calc(1.4 * var(--lh));
                width: 50vw;
                text-align: left;
                @media only screen and (max-width:768px) {
                    // padding: calc(1 * var(--lh)) var(--lh);
                    width: 100%;
                    height: auto;
                }
                &.inner-title {
                    cursor: pointer;
                }
                p {
                    margin-bottom: var(--lh);
                    &:last-of-type {
                        margin-bottom: 0;
                    }
                }
                p + .list:first-of-type {
                    margin-top: 0;
                }
                p:last-of-type + .list:first-of-type {
                    margin-top: var(--lh);
                }
                .list {
                    // check ios effect on list
                    position: relative;
                    display: flex;
                    &:first-of-type {
                        margin-top: var(--lh);
                    }
                    &:last-of-type {
                        margin-bottom: var(--lh);
                    }
                    .indent {
                        flex: 0 0 15%;
                        font-feature-settings: "tnum";
                        @media only screen and (max-width:768px) {
                          flex: 0 0 22%;
                        }
                    }
                    .text {
                        flex: 1;
                    }
                }
            }
            img,
            video {
                position: absolute;
                left: 0;
                top: 0;
                height: 100%;
                width: 50vw;
                object-fit: cover;
                &.mirror {
                    transform: scale(-1, 1) translate3d(0,0,0);
                }
                @media only screen and (max-width:768px) {
                    &.mirror {
                        transform: scale(1, -1) translate3d(0,0,0);
                    }
                    top: 0;
                    width: 100%;
                    height: 50vh;
                }
            }
        }
        .right {
            right: auto;
            left: 50%;
            @media only screen and (max-width:768px) {
                top: 50%;
                left: 0;
            }
            .inner-news,
            .inner-text {
                position: absolute;
                bottom: 0;
                right: 0;
                @media only screen and (max-width: 768px) {
                    position: initial;
                }
            }
            img,
            video {
                left: auto;
                right: 0;
                transform: scale(1);
                // transform: scale(-1, 1) translate3d(0,0,0);
                @media only screen and (max-width:768px) {
                    // transform: scale(1, -1) translate3d(0,0,0);
                    top: auto;
                    bottom: 0;
                }
            }
        }
    }
    &:first-of-type {
        .viewer {
            .left,
            .right {
                width: 50vw !important;
                height: 100vh !important;
                @media only screen and (max-width:768px) {
                    width: 100vw !important;
                    height: 50vh !important;
                }
            }
        }
    }
    &.past {
        .left,
        .right {
            width: 50% !important;
            height: 100% !important;
            @media only screen and (max-width:768px) {
                width: 100% !important;
                height: 50vh !important;
            }
        }
    }
    &.future {
        .left,
        .right {
            width: 0 !important;
            height: 100% !important;
            @media only screen and (max-width:768px) {
                width: 0 !important;
                height: 0 !important;
            }
        }
    }
}
</style>
